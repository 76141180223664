<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('HOME.ADMIN_ACCOUNT')">
          <template v-slot:body>
            <div class="col-md-10" v-if="securityWarning" @click="goSecurity">
              <b-alert show variant="danger">
                <b>{{ $t("SECURITY.SECURITY_WARNING") }}</b>
              </b-alert>
            </div>
            <div class="row table-filter">
              <div class="col-sm-6"></div>
              <div class="col-sm-6">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  :label="$t('COMMON.SEARCH')"
                  class="cus-searchBox"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
            </div>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="accountHistory"
              :single-select="singleSelect"
              :search="search"
              item-key="name"
              class="elevation-1"
              :footer-props="{
                showFirstLastPage: true,
              }"
            >
            </v-data-table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";
import { SETSTATUS } from "@/store/status.module";

/**
 * Sample widgets data source
 */
export default {
  name: "home",
  components: {
    KTPortlet,
  },
  data() {
    return {
      search: "",
      singleSelect: false,
      selected: [],
      headers: [
        { text: this.$t("COMMON.ADMIN"), value: "username" },
        { text: this.$t("COMMON.ACTION"), value: "action" },
        { text: this.$t("HOME.REMOTE_IP"), value: "remoteip" },
        { text: this.$t("COMMON.DATE"), value: "date1" },
      ],
      accountHistory: [],
    };
  },
  created() {
    // this.$i18n.locale = localStorage.language;
    const token = localStorage.id_token;
    postRequest({ action: "getAccountStats", token: token }).then((res) => {
      if (res && res.returncode) {
        this.accountHistory = res.returndata;
      }
    });
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.HOME") }]);
    this.$store.dispatch(SETSTATUS, {
      action: "getAdminStats",
      token: localStorage.id_token,
    });
  },
  computed: {
    basicAccount: function () {
      return this.$store.state.status.basicAccount;
    },
    enhancedAccount: function () {
      return this.$store.state.status.enhancedAccount;
    },
    domains: function () {
      return this.$store.state.status.domains;
    },
    quota: function () {
      return this.$store.state.status.quota;
    },
    securityWarning: function () {
      return localStorage.securityWarning == 1;
    },
    zulipAccount: function () {
      return this.$store.state.status.zulipAccount;
    },
  },
  methods: {
    goSecurity() {
      this.$router.push("/securityincidents");
    },
  },
};
</script>
